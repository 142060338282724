<template>
  <transition
    enter-active-class="animate__animated animate__slideInDown"
    leave-active-class="animate__animated animate__slideOutUp"
    mode="out-in"
  >
    <div class="info-modal-container" v-if="infoModal">
      <div @click="close" class="info-modal-overlay"></div>
      <div class="info-modal pa-0 px-9 pb-9">
        <div class="headline pa-2 close-modal" @click="close">X</div>
        <component v-if="currentInfoModal" :is="currentInfoModal+'-modal'"/>
      </div>
    </div>
  </transition>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex';

  /** Section One */
  import SecOneHollowMouldModal from './sectionOne/HollowMould'
  import SecOneTwoPartsModal from './sectionOne/TwoParts'
  import SecOneCaseMouldsModal from './sectionOne/CaseMoulds'
  import SecOneBaseplateModal from './sectionOne/Baseplate'
  import SecOneCoreModal from './sectionOne/Core'
  import SecOneCrookModal from './sectionOne/Crook'
  import SecOneShapeLiningModal from './sectionOne/ShapeLining'
  import SecOneInscriptionsModal from './sectionOne/Inscriptions'
  import SecOneTaylorsModal from './sectionOne/Taylors'
  import SecOneSpecialInscriptionsModal from './sectionOne/SpecialInscriptions'
  import SecOneMirrorWrittingModal from './sectionOne/MirrorWritting'

  /** Section Two */ 
  import SecTwoBellmetalModal from './sectionTwo/Bellmetal'
  import SecTwoReverberatoryFurnacesModal from './sectionTwo/ReverberatoryFurnaces'
  import SecTwoFacsimileInscriptionsModal from './sectionTwo/FacsimileInscriptions'

  /** Section Three */ 
  import SecThreeFeedingModal from './sectionThree/Feeding'

  /** Section Four */
  import SecFourBellBornModal from './sectionFour/BellBorn'

  export default {
    props: {
      modal: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters({
          infoModal: 'infoModal/infoModal',
          currentInfoModal: 'infoModal/currentInfoModal'
      }),
    },
    methods: {
      ...mapActions({
          setInfoModal: 'infoModal/setInfoModal'
      }),
      close(){
        this.setInfoModal(false)
      }
    },
    components: {
      /** Section one */
      SecOneHollowMouldModal,
      SecOneTwoPartsModal,
      SecOneCaseMouldsModal,
      SecOneBaseplateModal,
      SecOneCoreModal,
      SecOneCrookModal,
      SecOneShapeLiningModal,
      SecOneInscriptionsModal,
      SecOneTaylorsModal,
      SecOneSpecialInscriptionsModal,
      SecOneMirrorWrittingModal,

      /** Section two */
      SecTwoBellmetalModal,
      SecTwoReverberatoryFurnacesModal,
      SecTwoFacsimileInscriptionsModal,

      /** Section three */
      SecThreeFeedingModal,

      /** Section four */
      SecFourBellBornModal,
    }
  }
</script>

<style lang="scss" scoped>
.info-modal-container {
  width: 100%;
  height: calc(100% - 100px);
  position: absolute;
  bottom: 0;
  left: 0;
  .info-modal-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.6);
  }
  .info-modal{
    position: absolute;
    top: 0;
    background: #fff;
    right: 0;
    height: 100%;
    width: calc(100% - 100px);
    z-index: 4;
    .close-modal{
      position: absolute;
      right: 30px;
      top: 10px;
      cursor: pointer;
    }
  }
}

@media (max-width: 800px) {
  .info-modal-container {
    height: calc(100% - 90px);
    .info-modal {
      width: calc(100% - 90px);
    }
  }
}

@media (max-width: 767px) {
  .info-modal-container {
    height: 100vh;
    width: 100vw;
    z-index: 4;
    .info-modal {
      width: 90vw;
      height: 90vh;
      top: 5vh;
      left: 5vw;
    }
  }
}
</style>