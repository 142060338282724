<template>
  <div :style="sidebarStyle" :class="sidebarClasses" class="sidebar-navigation">
    <!--icon holder-->
    <div class="icon-holder">
      <v-icon class="selector" size="35" v-if="sidebarExpand" @click="sidebarClose()">$caretRight</v-icon>
      <v-icon class="selector closed" size="35" v-else @click="sidebarOpen()">$caretLeft</v-icon>
    </div>
    <div class="pa-2 white--text sidebar-content-container" v-if="sidebarExpand">
      <div class="sidebar-title d-flex">
        <h1 class="align-self-center">{{sidebarTitle}}</h1>
      </div>
      <!--sidebar body-->
      <div class="sidebar-body">
        <transition
          enter-active-class="animate__animated animate__fadeIn"
          leave-active-class="animate__animated animate__fadeOut"
          mode="out-in" 
        >
          <component 
            v-bind:is="currentSidebar+'-sidebar'"
            @updated="updateSidebar"
            @next-section="nextSection"
            @prev-section="prevSection"
          ></component> 
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import SecOneSidebar from '@/components/sidebar/sectionOne/index'
import SecTwoSidebar from '@/components/sidebar/sectionTwo/index'
import SecThreeSidebar from '@/components/sidebar/sectionThree/index'
import SecFourSidebar from '@/components/sidebar/sectionFour/index'

export default {
  data() {
    return {
      //show: this.sidebarShow ? true : false
    }
  },
  components: {
    SecOneSidebar,
    SecTwoSidebar,
    SecThreeSidebar,
    SecFourSidebar
  },
  computed: {
    ...mapGetters({
        currentSidebar: 'sidebar/currentSidebar',
        sidebarTitle: 'sidebar/sidebarTitle',
        sidebarMini: 'sidebar/sidebarMini',
        currentSidebar: 'sidebar/currentSidebar',
        currentSection: 'sections/currentSection',
        sidebarPageNumber: 'sidebar/sidebarPageNumber',
        sidebarExpand: 'sidebar/sidebarExpand',
    }),
    sidebarStyle(){
      return {
                backgroundColor:this.$store.state.colors.appBlue,
                width: this.sidebarExpand ? '500px' : '40px'
            }
    },
    sidebarClasses(){
      return {
        "sidebar-open":this.sidebarExpand,
        "sidebar-closed":!this.sidebarExpand
      }
    }
  },
  watch: {
    mini: function (val) {
      /** set vuex value for sidebar */
      this.setSidebarMinimize(val)
    }
  },
  methods: {
    ...mapActions({
        setNextSection: 'sections/setNextSection',
        setPrevSection: 'sections/setPrevSection',
        setCurrentPannel: 'pannels/setCurrentPannel',
        setCurrentSidebar: 'sidebar/setCurrentSidebar',
        setSidebarTitle: 'sidebar/setSidebarTitle',
        setSidebarPageNumber: 'sidebar/setSidebarPageNumber',
        setSidebarExpand: 'sidebar/setSidebarExpand',
        setInfoModal: 'infoModal/setInfoModal',
        setSlideDirection: 'animateSlide/setSlideDirection',
    }),
    /** Expand sidebar */
    sidebarOpen(){
      this.setSidebarExpand(true)
    },
    /** close sidebar */
    sidebarClose(){
      this.setSidebarExpand(false)
    },
    updateSidebar(sidebar){
      /** set info modal to false in case modal was open */
      this.setInfoModal(false)

      /** set animation direction */
      if(sidebar.id < this.sidebarPageNumber){
        this.setSlideDirection("slideRight")
      }
      else{
        this.setSlideDirection("slideLeft")
      }

      /** Update sidebar title */
      this.setSidebarTitle(sidebar.title)
      /** Set sidebar page view */
      this.setSidebarPageNumber(sidebar.id)
      /** Update pannel */
      if(sidebar.pannel){
        this.setCurrentPannel(sidebar.pannel)
      }
    },
    /** Next Section */
    nextSection(){
      /** set info modal to false in case modal was open */
      this.setInfoModal(false)

      /** set animattion name */
      this.setSlideDirection("slideLeft")

      this.setNextSection()
    },
    /** previous section */
    prevSection(){
      /** set info modal to false in case modal was open */
      this.setInfoModal(false)

      /** set animattion name */
      this.setSlideDirection("slideRight")

      this.setPrevSection()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/sidebar/sidebar.scss';

</style>
  
