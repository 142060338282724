import { render, staticRenderFns } from "./Page-4.vue?vue&type=template&id=219a9640&scoped=true&"
var script = {}
import style0 from "./Page-4.vue?vue&type=style&index=0&id=219a9640&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "219a9640",
  null
  
)

export default component.exports