const state = {
  infoModal : false,
  currentInfoModal: null
};

const mutations = {
    'SET_INFO_MODAL'(state, payload){
        state.infoModal = payload;
    },
    'SET_CURRENT_INFO_MODAL'(state, payload){
        state.currentInfoModal = payload;
    }
};

const actions = {
    /** Initialise pannel on app load */
    infoModalInit: ({commit}, payload) => {
      const modal = localStorage.getItem('infoModal')
      const modalStatus = modal == 'true' ? modal : false
      /** get current info modal */
      const currentModal = localStorage.getItem('currentInfoModal')
      
      /** Set Modal status */
      commit('SET_INFO_MODAL', modalStatus);

      /** set current info modal */
      commit('SET_CURRENT_INFO_MODAL', currentModal);

    },
    setInfoModal: ({commit, state}, payload)=>{
      /** Set state cur pannel */
      if(payload == true){
        commit('SET_INFO_MODAL', true);
        /** set local storage */
        localStorage.setItem('infoModal', true)
      }
      else{
        commit('SET_INFO_MODAL', false);
        /** set local storage */
        localStorage.removeItem('infoModal')
        localStorage.removeItem('currentInfoModal')
      }
    },
    setCurrentModal: ({commit, dispatch, state}, payload) => {
      /** Set current modal */
      commit('SET_CURRENT_INFO_MODAL', payload);

      /** set modal status */
      dispatch('setInfoModal', true)

      /** set current info modal */
      localStorage.setItem('currentInfoModal', payload)
    }
};

const getters = {
  infoModal : state => {
    return state.infoModal;
  },
  currentInfoModal: state => {
    return state.currentInfoModal
  }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}