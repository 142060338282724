<template>
  <div class="hotspot-container d-flex justify-center">
    <!--<img class="hotspot-image" :src="configs.imageUrl" />-->
    <v-img 
      :src="configs.imageUrl" 
      class="hotpost-image"
      :style="ImageStyles"
    />
    <!-- <div class="hotspot-image" :style="ImageStyles">

    </div> -->
    <div class="hotspot-points-container">
      <template v-for="(point, index) in configs.points">
        <hotspot-point 
          :key="index"
          :point="point"
          @info-link="showInfo"
        />
      </template>
    </div>
  </div>
</template>

<script>
  import HotspotPoint from './HotspotPoint'
  export default {
    props: {
      configs: {
        type: Object
      }
    },
    computed: {
      ImageStyles(){
        return {
          background: `url("${this.configs.imageUrl}") no-repeat`,
          backgroundSize: "cover"
        }
      }
    },
    components: {
      HotspotPoint
    },
    methods: {
      showInfo(modalName){
        this.$emit('showInfoModal', modalName)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .hotspot-container{
    width: 100%;
    height: 100%;
    position: relative;

    .hotspot-image .v-responsive__sizer {
      padding-bottom: calc(100vh - 100px) !important;
    }

    .hotspot-points-container{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
  
</style>