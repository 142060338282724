const state = {
  sections: [
    {id: 0, name: "Online Guide", defaultPannel: "home", defaultSidebar: {name: null}},
    {id: 1, name: "Mould Making", defaultPannel: "sec-one-pannel-one", defaultSidebar: {name: 'sec-one', totalPages: 8}},
    {id: 2, name: "Liquid Bells", defaultPannel: "sec-two-pannel-one", defaultSidebar: {name: 'sec-two', totalPages: 4}},
    {id: 3, name: "Casting", defaultPannel: "sec-three-pannel-one", defaultSidebar: {name: 'sec-three', totalPages: 3}},
    {id: 4, name: "Breaking Out", defaultPannel: "sec-four-pannel-one", defaultSidebar: {name: 'sec-four', totalPages: 2}}
  ],
  totalSections: 4,
  currentSection : null
};

const mutations = {
    'SET_CURRENT_SECTION'(state, section){
        /** Set state cur section */
        state.currentSection = section;
    }
};

const actions = {
    /** Initialise Section */
   sectionInit: ({commit, dispatch, state}, payload) => {
      let section = JSON.parse(localStorage.getItem('curSection'))
      const curSection = section ? section : state.sections[0]
      /** Set current section */
      commit('SET_CURRENT_SECTION', curSection)
    },
    setCurrentSection: ({commit, dispatch, state}, payload)=>{

      const section = state.sections.find(el => el.id === payload.sectionId)

      const curSection = section ? section : state.sections[0]
      /** Set current section */
      commit('SET_CURRENT_SECTION', curSection)
      /** Store section in local storage */
      localStorage.setItem('curSection', JSON.stringify(state.currentSection))
      /** Set section default data */
      dispatch('setSectionDefaultData', payload.previous)
    },
    setNextSection: ({dispatch, state}) => {
      const next = state.currentSection.id + 1
      if(next <= state.totalSections){
        dispatch('setCurrentSection', {sectionId:next})
      }
      else{
        dispatch('setCurrentSection', {sectionId: 0})
      }
    },
    setPrevSection: ({dispatch, state}) => {
      const sectionId = state.currentSection.id - 1
      if(sectionId >= 1){
        dispatch('setCurrentSection', {sectionId: sectionId, previous: true})
      }
      else{
        dispatch('setCurrentSection', {sectionId: 0, previous: true})
      }
    },
    /** get section default data */
    setSectionDefaultData: ({commit, dispatch, state}, previous) => {
      /** Set default pannel */
      dispatch('pannels/setCurrentPannel', state.currentSection.defaultPannel, {root: true})

      /** Set default sidebar */
      dispatch('sidebar/setCurrentSidebar', state.currentSection.defaultSidebar.name, {root: true})

      /** set page number */
      const pageNo = previous ? state.currentSection.defaultSidebar.totalPages : null
      dispatch('sidebar/setSidebarPageNumber', pageNo, {root: true})
    }
};

const getters = {
    currentSection: state => {
      return state.currentSection
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}