const state = {
  currentPannel : null
};

const mutations = {
    'SET_CURRENT_PANNEL'(state, pannel){
        state.currentPannel = pannel;
    }
};

const actions = {
    /** Initialise pannel on app load */
    pannelInit: ({commit, dispatch, state, rootState}, payload) => {
      const pannel = localStorage.getItem('curPannel')
      const curPannel = pannel ? pannel : rootState.sections.currentSection.defaultPannel

      /** Set state cur pannel */
      commit('SET_CURRENT_PANNEL', curPannel);
      /** set local storage */
      localStorage.setItem('curPannel', curPannel)
    },
    setCurrentPannel: ({commit, state}, payload)=>{
      /** Set state cur pannel */
      commit('SET_CURRENT_PANNEL', payload);

      /** set local storage */
      localStorage.setItem('curPannel', payload)
    }
};

const getters = {
  currentPanel : state => {
    return state.currentPannel;
  }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}