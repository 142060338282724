const state = {
  sidebarShow: false,
  sidebarExpand: false,
  currentSidebar : null,
  sidebarTitle: null,
  sidebarPageNumber: null
};

const mutations = {
  'SET_SIDEBAR_SHOW'(state, payload){
    state.sidebarShow = payload;
  },
  'SET_SIDEBAR_EXPAND'(state, payload){
    state.sidebarExpand=payload
  },
  'SET_CURRENT_SIDEBAR'(state, payload){
      state.currentSidebar = payload;
  },
  'SET_SIDEBAR_TITLE'(state, payload){
    state.sidebarTitle = payload;
  },
  'SET_SIDEBAR_PAGE_NUMBER'(state, payload){
    state.sidebarPageNumber = payload;
  }
};

const actions = {
  /** Initialise Sidebar on app load */
  sidebarInit : ({commit, dispatch, state, rootState}, payload) => {
    /** Set current sidebar */
    const sidebar = localStorage.getItem('curSidebar')
    const curSidebar = sidebar ? sidebar : rootState.sections.currentSection.defaultSidebar.name
    dispatch('setCurrentSidebar', curSidebar)

    /** Set Sidebar Page view */
    const pageNumber = localStorage.getItem('sidebarPageNumber')
    const curPageNumber = pageNumber ? pageNumber : null
    dispatch('setSidebarPageNumber', curPageNumber)

    /** Expand sidebar */
    const expand = localStorage.getItem('sidebarExpand')
    if(expand && expand == "expand"){
      dispatch('setSidebarExpand', true)
    }
  },
  setCurrentSidebar: ({commit, dispatch, state}, payload)=>{
    commit('SET_CURRENT_SIDEBAR', payload);
    
    if(payload){
      /** local storage */
      localStorage.setItem('curSidebar', payload)
      /** show side bar */
      commit('SET_SIDEBAR_SHOW', true);
    }
    else{
      /** local storage */
      localStorage.removeItem('curSidebar')
      /** show side bar */
      commit('SET_SIDEBAR_SHOW', false);

      /** minimize sidebar */
      dispatch('setSidebarExpand', false)
      
    }
  },
  /** Minimize */
  setSidebarExpand: ({commit, state}, payload)=>{
    const status = payload == true ? true : false
    commit('SET_SIDEBAR_EXPAND', status);

    if(status){
      /** local storage */
      localStorage.setItem('sidebarExpand', "expand")
    }
    else{
      /** local storage */
      localStorage.removeItem('sidebarExpand')
    }
  },
  /** Title */
  setSidebarTitle: ({commit, state}, payload)=>{
    commit('SET_SIDEBAR_TITLE', payload);
  },
  /** Page View */
  setSidebarPageNumber: ({commit, state}, payload)=>{
    commit('SET_SIDEBAR_PAGE_NUMBER', payload);
    /** update local storage  sidebarPageView */
    payload ? localStorage.setItem('sidebarPageNumber', payload) : localStorage.removeItem('sidebarPageNumber')
  },
};

const getters = {
  sidebarShow: state => {
    return state.sidebarShow
  },
  sidebarExpand: state => {
    return state.sidebarExpand
  },
  currentSidebar : state => {
    return state.currentSidebar;
  },
  sidebarTitle : state => {
    return state.sidebarTitle;
  },
  sidebarPageNumber: state => {
    return state.sidebarPageNumber
  }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}