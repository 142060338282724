import Vue from 'vue'
import Vuex from 'vuex'
import sections from './modules/sections'
import pannels from './modules/pannels'
import sidebar from './modules/sidebar'
import infoModal from './modules/infoModal'
import animateSlide from './modules/animateSlide'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    colors:{
      appRed: "#FF1200",
      appBlue: '#261D41',
    }
  },
  mutations: {
  },
  actions: {
    storeInit: ({commit, dispatch, state}, payload)=>{

      /** Initialise Section */
      dispatch('sections/sectionInit', null, {root: true})

      /** initialise pannel state */
      dispatch('pannels/pannelInit', null, {root: true})
      
      /** initialise sidebar */
      dispatch('sidebar/sidebarInit', null, {root: true})

      /** Initialise info modal */
      dispatch('infoModal/infoModalInit', null, {root: true})
    }
  },
  modules: {
    sections,
    pannels,
    sidebar,
    infoModal,
    animateSlide
  }
})
