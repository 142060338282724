import Vue from 'vue'
import App from './App.vue'
//import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import globalPlugin from './plugins/global-plugins'
import 'animate.css/animate.min.css'

Vue.config.productionTip = false

new Vue({
  //router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#online_guide_app')

//router.replace('/')
