import { render, staticRenderFns } from "./Page-3.vue?vue&type=template&id=1dace205&scoped=true&"
var script = {}
import style0 from "./Page-3.vue?vue&type=style&index=0&id=1dace205&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dace205",
  null
  
)

export default component.exports