<template>
  <div>
    <div class="sidebar-content">
      <transition-group tag="div" :name="slideDirection">
        <page-one v-if="pageView=='page-one'" :key="1"/>
        <page-two v-if="pageView=='page-two'" :key="2"/>
        <page-three v-if="pageView=='page-three'" :key="3"/>
        <page-four v-if="pageView=='page-four'" :key="4"/>
        <page-five v-if="pageView=='page-five'" :key="5"/>
        <page-six v-if="pageView=='page-six'" :key="6"/>
        <page-seven v-if="pageView=='page-seven'" :key="7"/>
        <page-eight v-if="pageView=='page-eight'" :key="8"/>
      </transition-group>
    </div>

    <!--Sidebar Pagination-->
    <div class="pagination">
      <app-pagination
        :values="paginationValues"
        @updated="pageUpdated"
        @over-length="nextSection"
        @under-length="prevSection"
        :startPage="sidebarPageNumber"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import PageOne from './pages/Page-1'
import PageTwo from './pages/Page-2'
import PageThree from './pages/Page-3'
import PageFour from './pages/Page-4'
import PageFive from './pages/Page-5'
import PageSix from './pages/Page-6'
import PageSeven from './pages/Page-7'
import PageEight from './pages/Page-8'
export default {
  data(){
    return {
      paginationValues: [
        {id: 1, title: "Mould Making", pannel:"sec-one-pannel-one", pageView:"page-one"},
        {id: 2, title: "Mould Making", pannel: "sec-one-pannel-two", pageView:"page-two"},
        {id: 3, title: "The Core", pannel:"sec-one-pannel-three", pageView:"page-three"},
        {id: 4, title: "The Cope", pannel:"sec-one-pannel-four", pageView:"page-four"},
        {id: 5, title: "The Cope", pannel:"sec-one-pannel-five", pageView:"page-five"},
        {id: 6, title: "Finishing the mould", pannel:"sec-one-pannel-six", pageView:"page-six"},
        {id: 7, title: "Finishing the mould", pannel:"sec-one-pannel-seven", pageView:"page-seven"},
        {id: 8, title: "Finishing the mould", pannel:"sec-one-pannel-eight", pageView:"page-eight"}
      ],
    }
  },
  computed: {
    ...mapGetters({
        sidebarPageNumber: 'sidebar/sidebarPageNumber',
        slideDirection: 'animateSlide/slideDirection'
    }),
    pageView(){
      const pageItem = this.paginationValues.find(el => el.id === this.sidebarPageNumber)
      return pageItem ? pageItem.pageView : null
    }
  },
  components: {
    PageOne,
    PageTwo,
    PageThree,
    PageFour,
    PageFive,
    PageSix,
    PageSeven,
    PageEight
  },
  methods: {
    pageUpdated(sidebar){
      this.$emit('updated', sidebar)
    },
    nextSection(){
      this.$emit('next-section')
    },
    prevSection(){
      this.$emit('prev-section')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/sidebar/sidebar-content.scss';
  @import '@/assets/scss/siteAnimations.scss';
</style>