<template>
  <div class="pannel-container" >
    <app-hotsopt
      :configs="hotspotConfigs"
    ></app-hotsopt>
  </div>
</template>

<script>
export default {
  data(){
    return{
      hotspotConfigs: {
        imageUrl: require("@/assets/Home/taylorBellsCastingHall_20201112_298.jpg"),
        points: [
          {
            hotSpot: {
              label: 1,
              top: "20.5%",
              left: "55.5%",
              sectionLink: true,
              sectionId: 1
            },
            balloon: {
              text: "Mould Making",
              top: "19.5%",
              left: "57.5%"
            }
          },
          {
            hotSpot: {
              label: 2,
              top: "33%",
              left: "63%",
              sectionLink: true,
              sectionId: 2
            },
            balloon: {
              text: "Liquid Bells",
              top: "32%",
              left: "65%"
            }
          },
          {
            hotSpot: {
              label: 3,
              top: "68%",
              left: "40.5%",
              sectionLink: true,
              sectionId: 3
            },
            balloon: {
              text: "Casting",
              top: "67%",
              left: "42.5%"
            }
          },
          {
            hotSpot: {
              label: 4,
              top: "79.5%",
              left: "29%",
              sectionLink: true,
              sectionId: 4
            },
            balloon: {
              text: "Breaking Out",
              top: "78.5%",
              left: "31%"
            }
          }
        ]
      }
    }
  }
}
</script>

