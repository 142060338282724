<template>
  <div class="hotspot-item">
    <div class="hotspot d-flex justify-center" 
      :style="labelStyles" 
      ref="hotspotPin"
      @mouseover="showPinContent()"
      @mouseleave="showContent=false"
      @click="clicked"
    >
      <label class="align-self-center" style="cursor:pointer;">{{point.hotSpot.label}}</label>
    </div>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <div ref="hotspotContent" class="content" v-if="showContent" :style="ContentStyles">{{point.balloon.text}}</div>
    </transition>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
export default {
  props: {
    point: {
      type: Object,
      default: () => ({
        hotSpot: {
          labelColor: "#FF1200"
        }
      })
    }
  },
  data(){
    return {
      showContent: false,
      //ContentStyles: {}
    }
  },
  computed: {
    ...mapGetters({
        currentSection: 'sections/currentSection'
    }),
    labelStyles(){
      return{
        color: this.point.hotSpot.labelColor ? this.point.hotSpot.labelColor : this.$store.state.colors.appRed,
        height: this.point.hotSpot.height ? this.point.hotSpot.height : "35px",
        width: this.point.hotSpot.width ? this.point.hotSpot.width : "35px",
        borderRadious: this.point.hotSpot.radius ? this.point.hotSpot.radius : "50%",
        top: this.point.hotSpot.top,
        left: this.point.hotSpot.left
      }
    },
    ContentStyles(){
      return {
        top: this.point.balloon.top,
        left: this.point.balloon.left
      }
    }
  },
  methods: {
    ...mapActions({
        setCurrentSetion: "sections/setCurrentSection",
        setCurrentPannel: 'pannels/setCurrentPannel',
        setCurrentSidebar: 'sidebar/setCurrentSidebar',
        setSidebarPageNumber: 'sidebar/setSidebarPageNumber',
        setCurrentModal: 'infoModal/setCurrentModal'
    }),
    showPinContent() {
      this.showContent=true
    },
    clicked(){
      if(this.point.hotSpot.sectionLink){
        /** Set current section */
        this.setCurrentSetion({sectionId: this.point.hotSpot.sectionId})
      }
      else if(this.point.hotSpot.infoModal){
        //infoModal
        this.setCurrentModal(this.point.hotSpot.modalName)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .hotspot-item {
      .hotspot {
        position: absolute;
        background-color: #fff;
        cursor: pointer;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        text-align: center;
        z-index: 3;
        animation: pulse-effect 1.5s ease-in-out infinite;
      }
      //.pin::after{
      //  content: "";
      //  position: absolute;
      //  top: 0;
      //  left: 0;
      //  height: 100%;
      //  width: 100%;
      //  background-color: #FFF;
      //  border-radius: 50%;
      //  z-index: 0;
      //  animation: pulse-effect 1s ease-in-out infinite;
      //}
      @keyframes pulse-effect {
        //0%{
        //  transform: scale(.5);
        //  opacity: 0.6;
        //}
        //100%{
        //  transform: scale(2);
        //  opacity: 0;
        //}
        from{
          box-shadow: 0 0 0 0 #FFF;
        }
        to{
          box-shadow: 0 0 0 10px rgb(255, 255, 255, 0.3);
        }
      }
      .content {
        position: absolute;
        //background-color: #333;
        color: #FFF;
        font-size: 25px;
        padding: 10px 20px;
        max-width: 300px;
        pointer-events: none;
        z-index: 2;
      }
    }

    @media (max-width: 1100px) {
      .hotspot-item {
        .hotspot {
          height: 32px !important;
          width: 32px !important;
          label {
            font-size: 14px;
          }
        }
        .content {
          font-size: 20px;
        }
      }
    }

    @media (max-width: 700px) {
      .hotspot-item {
        .hotspot {
          height: 28px !important;
          width: 28px !important;
          label {
            font-size: 12px;
          }
        }
        .content {
          font-size: 18px;
        }
      }
    }

    @media (max-width: 600px) {
      .hotspot-item {
        .content {
          font-size: 16px;
        }
      }
    }

    @media (max-width: 500px) {
      .hotspot-item {
        .content {
          font-size: 14px;
        }
      }
    }

    @media (max-width: 400px) {
      .hotspot-item {
        .hotspot {
          height: 25px !important;
          width: 25px !important;
          label {
            font-size: 10px;
          }
        }
        .content {
          font-size: 12px;
        }
      }
    }
    
</style>