<template>
  <div class="outer-container">
    <transition-group tag="div" class="pannel-slider d-flex" :name="slideDirection">
      <home-pannel v-if="currentPanel == 'home'" :key="1" />

      <!--Section One-->
      <sec-one-pannel-one  v-if="currentPanel == 'sec-one-pannel-one'" :key="2"/>
      <sec-one-pannel-two  v-if="currentPanel == 'sec-one-pannel-two'" :key="3"/>
      <sec-one-pannel-three  v-if="currentPanel == 'sec-one-pannel-three'" :key="4"/>
      <sec-one-pannel-four  v-if="currentPanel == 'sec-one-pannel-four'" :key="5"/>
      <sec-one-pannel-five  v-if="currentPanel == 'sec-one-pannel-five'" :key="6"/>
      <sec-one-pannel-six  v-if="currentPanel == 'sec-one-pannel-six'" :key="7"/>
      <sec-one-pannel-seven  v-if="currentPanel == 'sec-one-pannel-seven'" :key="8"/>
      <sec-one-pannel-eight  v-if="currentPanel == 'sec-one-pannel-eight'" :key="9"/>


      <!--Section Two-->
      <sec-two-pannel-one  v-if="currentPanel == 'sec-two-pannel-one'" :key="10"/>
      <sec-two-pannel-two  v-if="currentPanel == 'sec-two-pannel-two'" :key="11"/>
      <sec-two-pannel-three  v-if="currentPanel == 'sec-two-pannel-three'" :key="12"/>
      <sec-two-pannel-four  v-if="currentPanel == 'sec-two-pannel-four'" :key="13"/>

      <!--Section Three-->
      <sec-three-pannel-one  v-if="currentPanel == 'sec-three-pannel-one'" :key="14"/>
      <sec-three-pannel-two  v-if="currentPanel == 'sec-three-pannel-two'" :key="15"/>
      <sec-three-pannel-three  v-if="currentPanel == 'sec-three-pannel-three'" :key="16"/>

      <!--Section Four-->
      <sec-four-pannel-one  v-if="currentPanel == 'sec-four-pannel-one'" :key="17"/>
      <sec-four-pannel-two  v-if="currentPanel == 'sec-four-pannel-two'" :key="18"/>

    </transition-group>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

import HomePannel from '@/components/pannels/home/index'

/** Section One */
import SecOnePannelOne from '@/components/pannels/sectionOne/PannelOne'
import SecOnePannelTwo from '@/components/pannels/sectionOne/PannelTwo'
import SecOnePannelThree from '@/components/pannels/sectionOne/PannelThree'
import SecOnePannelFour from '@/components/pannels/sectionOne/PannelFour'
import SecOnePannelFive from '@/components/pannels/sectionOne/PannelFive'
import SecOnePannelSix from '@/components/pannels/sectionOne/PannelSix'
import SecOnePannelSeven from '@/components/pannels/sectionOne/PannelSeven'
import SecOnePannelEight from '@/components/pannels/sectionOne/PannelEight'

/** Section Two */
import SecTwoPannelOne from '@/components/pannels/sectionTwo/PannelOne'
import SecTwoPannelTwo from '@/components/pannels/sectionTwo/PannelTwo'
import SecTwoPannelThree from '@/components/pannels/sectionTwo/PannelThree'
import SecTwoPannelFour from '@/components/pannels/sectionTwo/PannelFour'

/** Section Three */
import SecThreePannelOne from '@/components/pannels/sectionThree/PannelOne'
import SecThreePannelTwo from '@/components/pannels/sectionThree/PannelTwo'
import SecThreePannelThree from '@/components/pannels/sectionThree/PannelThree'

/** Section Four */
import SecFourPannelOne from '@/components/pannels/sectionFour/PannelOne'
import SecFourPannelTwo from '@/components/pannels/sectionFour/PannelTwo'

export default {
  computed: {
    ...mapGetters({
        currentPanel: 'pannels/currentPanel',
        slideDirection: 'animateSlide/slideDirection'
    })
  },
  components: {
    HomePannel,
    /** Section One */
    SecOnePannelOne,
    SecOnePannelTwo,
    SecOnePannelThree,
    SecOnePannelFour,
    SecOnePannelFive,
    SecOnePannelSix,
    SecOnePannelSeven,
    SecOnePannelEight,

    /** Section Two */
    SecTwoPannelOne,
    SecTwoPannelTwo,
    SecTwoPannelThree,
    SecTwoPannelFour,

    /** Section Three */
    SecThreePannelOne,
    SecThreePannelTwo,
    SecThreePannelThree,

    /** Section Four */
    SecFourPannelOne,
    SecFourPannelTwo

  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/pannel/pannel.scss';
@import '@/assets/scss/siteAnimations.scss';

</style>