<template>
  <div class="d-flex header">
    <!--Logo-->
    <div>
      <v-img 
        src="@/assets/Logo/Online Guide Logo Red Loughborough Bellfoundry Trust.svg" 
        :height="100" 
        :width="100"
        class="logo"></v-img>
    </div>

    <!--Page title-->
    <div class="align-self-center page_title d-flex flex-column flex-md-row">
      <span>
        <span class="mr-1 mr-sm-1 mr-md-2 mr-lg-3 mr-lg-3" :style="{color: $store.state.colors.appRed}" v-if="currentSection && currentSection.id >0">{{currentSection.id}}/</span>
        <span>{{currentSection.name}}</span>
      </span>
      <!--Header pagination-->
      <div class="header-pagination d-flex flex-sm-row flex-md-column flex-lg-column flex-xl-column flex-row ml-xl-5 ml-lg-5 ml-md-3 ml-sm-0 ml-0">
        <div class="d-flex align-items-center mt-auto mr-xl-0 mr-lg-0 mr-md-0 mr-sm-3 mr-1">
          <span class="pointer" @click="prevSection()"><v-icon class="mr-2">$caretLeft</v-icon>Prev</span>
        </div>
        <div class="d-flex align-items-center mb-auto ml-xl-0 ml-lg-0 ml-md-0 ml-sm-3 ml-1">
          <span class="pointer" @click="nextSection()">Next<v-icon class="ml-2">$caretRight</v-icon></span>
        </div>
      </div>
    </div>

    <v-spacer></v-spacer>

    <!--Back to foundry hall-->
    <div class="align-self-center foundry_link d-flex mr-3 mr-sm-5">
      <span class="pointer" :class="hallLinkClasses" @click="homeSection()">Back to foundry hall<v-icon class="ml-2">$caretRight</v-icon></span>
    </div>
    <div class="lottery-logo">
      <v-img 
        src="@/assets/Logo/Lottery-Heritage-Fund.png" 
        :height="auto" 
        :width="270">
      </v-img>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {

  computed: {
    ...mapGetters({
        currentSection: 'sections/currentSection',
        sidebarPageNumber: 'sidebar/sidebarPageNumber'
    }),
    hallLinkClasses(){
      if(this.sidebarPageNumber == this.currentSection.defaultSidebar.totalPages){
        return {
          'shake' : true
        }
      }
    }
  },
  methods: {
    ...mapActions({
        setNextSection: 'sections/setNextSection',
        setPrevSection: 'sections/setPrevSection',
        setCurrentSetion: "sections/setCurrentSection",
        setInfoModal: 'infoModal/setInfoModal',
        setSlideDirection: 'animateSlide/setSlideDirection'
       
    }),
    nextSection(){
      /** set info modal to false in case modal was open */
      this.setInfoModal(false)

      /** Set animation direction */
      this.setSlideDirection("slideLeft")

      this.setNextSection()
    },
    prevSection(){
      /** set info modal to false in case modal was open */
      this.setInfoModal(false)

      /** Set animation direction */
      this.setSlideDirection("slideLeft")

      this.setPrevSection()
    },
    homeSection(){
      /** set info modal to false in case modal was open */
      this.setInfoModal(false)

      /** Set animation direction */
      this.setSlideDirection("slideRight")

      this.setCurrentSetion(0)
    }
  }
}
</script>


<style lang="scss" scoped>

.header {
  position: relative;
}

.lottery-logo {
  position: fixed;
  left: 5px;
  bottom: 10px;
  z-index: 3;
}

.page_title {
  font-size: 45px;
  text-transform: uppercase;
  margin-left: 20px;
  letter-spacing: 2px;
  .header-pagination {
    span {
      font-size: 14px;
      text-transform: none;
      color: #8E9AAF;
      letter-spacing: 0;
      .v-icon {
        font-size: 14px;
        margin-top: -4px;
        color: #8E9AAF;
      }
    }
  }
}
.foundry_link {
  span {
    color: #E52B1F;
    font-size: 14px;
    &.shake {
      animation: shake 3s cubic-bezier(.36,.07,.19,.97) both;
      animation-iteration-count: infinite;
    }
    .v-icon {
      font-size: 15px;
      margin-top: -6px;
    }
  }
}

@keyframes shake {
  0% { transform:translate(0,0); }
  3% { transform:translate(5px,0); }
  6% { transform:translate(0,0); }
  9% { transform:translate(5px,0); }
  12% { transform:translate(0,0); }
  15% { transform:translate(5px,0); }
  18% { transform:translate(0,0); }
  100% { transform:translate(0,0); }
}

.pointer{
  cursor: pointer;
}

    @media (max-width: 1400px) {
      .page_title {
        font-size: 40px;
      }
    }

    @media (max-width: 1300px) {
      .page_title {
        font-size: 33px;
      }
    }

    @media (max-width: 1200px) {
      .page_title {
        font-size: 30px;
        letter-spacing: 1px;
        .header-pagination {
          span {
            font-size: 12px;
          }
        }
      }
      .foundry_link {
        span {
          font-size: 12px;
        }
      }
    }

    @media (max-width: 1100px) {
      .page_title {
        font-size: 25px;
        margin-left: 15px;
        .header-pagination span {
          font-size: 11px;
        }
      }
    }

    @media (max-width: 1000px) {
      .page_title {
        font-size: 22px;
        margin-left: 10px;
      }
      .foundry_link span {
        font-size: 11px;
      }
    }

    @media (max-width: 900px) {
      .page_title {
        font-size: 20px;
        letter-spacing: 0;
      }
      .foundry_link span {
        font-size: 10px;
      }
    }

    @media (max-width: 800px) {
      .logo {
        width: 90px !important;
        height: 90px !important;
      }
      .lottery-logo {
        .v-image {
          width: 240px !important;
        }
      }
    }

    @media (max-width: 767px) {
      .lottery-logo {
        bottom: 60px;
        z-index: 1;
        .v-image {
          width: 170px !important;
        }
      }
    }

    @media (max-width: 700px) {
      .logo {
        width: 85px !important;
        height: 85px !important;
      }
    }

    @media (max-width: 599px) {
      .foundry_link {
        position: absolute;
        right: 0px;
        bottom: 11px;
      }
    }

    @media (max-width: 500px) {
      .page_title {
        font-size: 23px;
        > span {
          margin-bottom: 5px;
        }
        .header-pagination {
          span {
            font-size: 10px;
          }
        }
      }
      .foundry_link {
        bottom: 14px;
        span {
          font-size: 10px;
        }
      }
    }

    @media (max-width: 400px) {
      .logo {
        width: 75px !important;
        height: 75px !important;
      }
      .page_title {
        font-size: 20px;
        letter-spacing: 1px;
      }
      .foundry_link {
        bottom: 13px;
      }
    }

</style>
