<template>
  <div>
    <div class="sidebar-content">
      <transition-group tag="div" :name="slideDirection">
        <page-one v-if="pageView=='page-one'" :key="1"/>
        <page-two v-if="pageView=='page-two'" :key="2"/>
      </transition-group>
    </div>

    <!--Sidebar Pagination-->
    <div class="pagination">
      <app-pagination
        :values="paginationValues"
        @updated="pageUpdated"
        @over-length="nextSection"
        @under-length="prevSection"
        :startPage="sidebarPageNumber"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import PageOne from './pages/Page-1'
import PageTwo from './pages/Page-2'
export default {
  data(){
    return {
      paginationValues: [
        {id: 1, title: "Breaking Out", pannel:"sec-four-pannel-one", pageView:"page-one"},
        {id: 2, title: "A bell is born", pannel:"sec-four-pannel-two", pageView:"page-two"},
      ],
    }
  },
  computed: {
    ...mapGetters({
        sidebarPageNumber: 'sidebar/sidebarPageNumber',
        slideDirection: 'animateSlide/slideDirection'
    }),
    pageView(){
      const pageItem = this.paginationValues.find(el => el.id === this.sidebarPageNumber)
      return pageItem ? pageItem.pageView : null
    }
  },
  components: {
    PageOne,
    PageTwo
  },
  methods: {
    pageUpdated(sidebar){
      this.$emit('updated', sidebar)
    },
    nextSection(){
      this.$emit('next-section')
    },
    prevSection(){
      this.$emit('prev-section')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/sidebar/sidebar-content.scss';
  @import '@/assets/scss/siteAnimations.scss';
</style>