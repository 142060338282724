import { render, staticRenderFns } from "./HotspotPoint.vue?vue&type=template&id=18aa5371&scoped=true&"
import script from "./HotspotPoint.vue?vue&type=script&lang=js&"
export * from "./HotspotPoint.vue?vue&type=script&lang=js&"
import style0 from "./HotspotPoint.vue?vue&type=style&index=0&id=18aa5371&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18aa5371",
  null
  
)

export default component.exports