<template>
    <span :style="slotStyle" @click="openModal()" class="modal-link">
      <slot/>
    </span>
</template>

<script>
import {mapActions} from 'vuex';
export default {
  props: {
    color: {
      type: String,
      default: "#FF1200"
    },
    modalName: {
      type: String
    },
    size: {
      type: String
    }
  },
  computed: {
    slotStyle(){
      return {
        color: this.color,
        fontSize: this.size ? this.size : null
      }
    }
  },
  methods: {
    ...mapActions({
      setCurrentModal: 'infoModal/setCurrentModal'
    }),
    openModal(){
      this.setCurrentModal(this.modalName)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-link{
  cursor: pointer;
}
</style>
