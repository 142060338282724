<template>
  <!--App Container-->
  <v-app class="app">
    <!--Main Container-->
    <v-main class="main-container">
      <div class="d-flex">
        <!--Header and pannel section-->
        <div class="content-container flex-grow-1">
          <!--Header-->
          <app-header />
          <!--Pannels-->
          <app-pannels />

           <!--Info Modal-->
          <info-modal />
        </div>

        <div class="sidebar-container">
          <app-sidebar v-if="sidebarShow"/>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import AppHeader from '@/components/layouts/Header'
import AppSidebar from '@/components/sidebar/Sidebar'
import AppPannels from '@/components/pannels/index'
import InfoModal from '@/components/infoModal/InfoModal'
export default {
  name: 'App',
  components: {
    AppHeader,
    AppSidebar,
    AppPannels,
    InfoModal
  },
  computed: {
    ...mapGetters({
        currentPanel: 'pannels/currentPanel',
        sidebarShow: 'sidebar/sidebarShow',
    }),
  },
  methods: {
    ...mapActions({
        storeInit: 'storeInit',
        setCurrentSetion: 'sections/setCurrentSection',
    }),
  },
  created(){
    //this.setCurrentSetion(0)
    this.storeInit()
  }
};
</script>

<style lang="scss">

@import '@/assets/scss/pannel/pannel-container.scss';

// .sidebar{
//   background: blue;
// }

/* FONTS */
@font-face {
    font-family: "Lorin-Bold";
    font-style: normal;
    font-weight: 700;
    src: url('./assets/Fonts/Lorin-Bold.woff') format('woff');
}
@font-face {
    font-family: "Lorin-ExtraBold";
    font-style: normal;
    font-weight: 800;
    src: url('./assets/Fonts/Lorin-ExtraBold.woff') format('woff');
}
@font-face {
    font-family: "Lorin-Light";
    font-style: normal;
    font-weight: 300;
    src: url('./assets/Fonts/Lorin-Light.woff') format('woff');
}
@font-face {
    font-family: "Lorin-Regular";
    font-style: normal;
    font-weight: 400;
    src: url('./assets/Fonts/Lorin-Regular.woff') format('woff');
}

  //change font family
  body, body p, body li {
    font-family: "Lorin-Regular" !important;
  }

  .app{
    font-family: "Lorin-Regular" !important;
  }

  .content-container{
    position: relative;
  }

</style>