<template>
  <div class="d-flex justify-space-between ">
    <v-btn
      depressed
      outlined
      tile
      color="#FFF"
      @click="prevPage"
      class="pagination-button"
    > 
      <span v-if="currentPage==1">Prev Section</span>
      <span v-else>Prev</span>
    </v-btn>
    <div class="paginatior d-flex justify-center flex-wrap">
        <div class="pages px-2" 
          v-for="page in totalPages" 
          :key="page"
          :class="selectedClass(page)"
          @click="selectPage(page)"
        >
          {{page}}
        </div>
    </div>
    <v-btn
      tile
      depressed
      dark
      :color="$store.state.colors.appRed"
      @click="nextPage"
      class="pagination-button"
    >
      <span v-if="currentPage==totalPages">Next Section</span>
      <span v-else>Next</span>
    </v-btn>

  </div>
</template>

<script>
export default {
  props: {
    length: {
      type: Number,
      default: 3
    },
    values: {
      type: Array
    },
    startPage: {
      type: [String, Number],
      default: null
    }
  },
  data(){
    return {
      currentPage: this.startPage ? parseInt(this.startPage) : 1
    }
  },
  created(){
    this.emitPageValue()
  },
  computed: {
    totalPages(){
      return this.values ? this.values.length : this.length
    }
  },
  methods: {
    emitPageValue(){
      const pageValue = this.values ? this.values.find(el => el.id === this.currentPage) : this.currentPage
      this.$emit('updated', pageValue)
    },
    selectPage(pageNo){
      this.currentPage = pageNo
      this.emitPageValue()
    },
    selectedClass(pageNo){
      return this.currentPage == pageNo ? "selected" : ""
    },
    nextPage(){
      const next = this.currentPage + 1
      if(next <= this.totalPages){
        this.currentPage = next
        this.emitPageValue()
      }
      else{
        this.$emit('over-length')
      }
    },
    prevPage(){
      const prev = this.currentPage - 1
      if(prev >= 1){
        this.currentPage = prev
        this.emitPageValue()
      }
      else{
        this.$emit('under-length')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .paginatior{
    .pages{
      cursor: pointer;
    }
    .pages.selected{
      color: #FF1200;
    }
  }

  @media (max-width: 1100px) {
    .pagination-button {
      height: 30px !important;
      min-width: 60px !important;
      padding: 0 10px !important;
      font-size: 12px !important;
    }
  }

  @media (max-width: 400px) {
    .pagination-button {
      height: 25px !important;
      min-width: 50px !important;
      padding: 0 5px !important;
      font-size: 10px !important;
    }
  }
</style>