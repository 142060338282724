<template>
  <div class="pannel-container" >
    <app-hotsopt
      :configs="hotspotConfigs"
    ></app-hotsopt>
  </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  data(){
    return{
      hotspotConfigs: {
        imageUrl: require("@/assets/Images/sectionThree/Casting 4.jpg"),
        
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/pannel/pannel.scss'
</style>
