const state = {
  direction : "slideLeft"
}

const mutations = {
    'SET_SLIDE_DIRECTION'(state, payload){
        state.direction = payload;
    }
}

const actions = {
    setSlideDirection: ({commit, state}, payload)=>{
      if(payload == "slideRight"){
        commit('SET_SLIDE_DIRECTION', "slideRight")
      }
      else{
        commit('SET_SLIDE_DIRECTION', "slideLeft")
      }
    }
}

const getters = {
  slideDirection : state => {
    return state.direction;
  }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}